<template>
    <div class="background">
        <div class="overlay"></div>
        <div class="container-fluid">
            <div class="header pt-10">
                <div class="logo"></div>
                <div class="login"></div>
            </div>
            <div class="col-lg-12 col-sm-12 login-container">
                <div class="col-lg-8 col-sm-8 app-title">
                <div class="logo"><img src="logo.png" alt="PlePower"></div>
                   <h2>PlePower</h2> 
                   <p>Fast, Reliable & Secured Transactions</p>
                </div>
                <div class="col-lg-4 col-sm-4 login-form">
                    <div class="login-form-inner">
                        <p class="text-black text-uppercase">Login</p>
                        <div class="inputs"> 
                            <label for="">Email/Phone</label>
                            <input type="email" class="form-control inputs-field" placeholder="Enter email / phone" v-model="username">
                            <label for="">Password</label>
                            <input type="password" class="form-control inputs-field" placeholder="Enter password" v-model="password">
                        </div>
                        <div class="inputs input-center">
                            <p class="forgot-password"> <router-link to="/forgot-password">Forgot your password?</router-link> | <a href="https://prolegacy.com.ng">PlePower Website</a></p>
                        </div>
                        <div class="inputs">
                            <button id="submit4" class="btn btn-success btn-block" :disabled="isSubmitButtonDisabled" @click="login()" v-if="!loading">Login</button>
                            <button class="btn btn-success btn-block" type="button" disabled v-if="loading">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                        </div>
                    </div>
                    <div class="footer">
                        <div class="copyright mt-4">
                            <p>Don't have an account? <span><router-link to="/register">Get Started</router-link></span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    <script>
    import Vue from 'vue'
    import VueCountryCode from "vue-country-code";
    import swal from 'sweetalert2';
    
    Vue.use(VueCountryCode);
    
    export default {
        
        name: 'SellVendorSigninPage',
        components: {
    
        },
        
        data() {
        return {
          appTitle: "PlePower",
         
          submit_4: false,
          username: "",
          password: "",
          loading: false,
        };
      },
    
      beforeMount(){
        document.title = this.appTitle
      },
      mounted() {
       
      },
      created(){
        
      },
      computed: {
        isSubmitButtonDisabled() {
        return (
          this.username === '' ||
          this.password === ''
        );
      }
      },
    
      methods:{
    
        async login() {
                try {
    
                    this.loading = true
                    const response =  await this.$http.post('/user-login', {
                        phone_number: this.username,
                        password: this.password
                    });
    
                    this.loading = false
    
                    if( !response.data.success ){
                        swal.fire({
                            icon: 'error',
                            title: 'Error',
                            width: 450,
                            height: 5,
                            text: response.data.message,
                        })
                        return;
                    }
    
                    let token = response.data.token;
                    await localStorage.setItem("wertyuiojhdfghhdrtfyguh", token)
                    await localStorage.setItem("firstname", response.data.user_profile[0].firstname)
                    await localStorage.setItem("lastname", response.data.user_profile[0].lastname)
                    await localStorage.setItem("email", response.data.user_profile[0].email_address)
                    await localStorage.setItem("phone", response.data.user_profile[0].phone_number)
                    await localStorage.setItem("userid", response.data.user_profile[0].userid)
                    await localStorage.setItem("user_role", response.data.user_profile[0].user_role)

                    this.$router.push('/home')
                   
                } catch (err) {
                  console.log(err);
            }
        },
        
      }
    }
    </script>
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
        .background{
            height: 100%;
            position: relative;
            backdrop-filter: blur(16px);
            background: url(../assets/bg.jpg) no-repeat fixed center;
            background-size: cover;
        }
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba(36, 86, 43, 0.921)/* Change the color and opacity here */
          }
        .input-center{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        .img-vendor{
            width: 40%;
        }
      
        .container-x{
            padding: 1.4rem;
        }
        .login-container{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            //align-items: center;
        }
        body, html {
            height: 100%;
            margin: 0;
        }
        .header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            padding: 8px;
            text-transform:uppercase;
            font-size: 12px;
            font-weight: 700;
            color: #fff;
            font-family: Poppins;
    
            .logo{
                
            }
    
            a{
                color: #fff;
            }
        }
        .get-started{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 10px;
            padding-bottom: 20px;
            font-family: Poppins;
            font-size: 25px;
            font-weight: 600;
            word-wrap: break-word;
            text-align: center;
            color: #fff;
        }
        .app-title{
            display: flex;
            flex-direction: column;
            align-items:center;
            justify-content: center;
            font-family: Poppins;
            font-size: 30px;
            font-weight: bold;
            text-align: right;
            color: #fff;
            padding: 4rem;
            text-transform: capitalize;

            .logo{
                img{
                    height: 70px;
                    width: auto;
                }
            }
           
            h2{
                font-family: Poppins;
                font-size: 60px;
                color: white;
                //text-shadow: 2px 2px 4px blue;
            }
            p{
                font-family: Poppins;
                font-size: 14px;
                font-weight: bold;
                color: #fff;
                text-transform: capitalize;
                text-align: center;
            }
        }
        .login-form{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
    
        .forgot-password{
            a {
                color: #24562B;
                text-decoration: none;
                font-family: Poppins;
                font-size: 10px;
            }
        }
        .login-form-inner{
            display: flex;
            flex-direction: column;
            background: #fff;
            border-radius: 5px;
            padding: 2rem;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            font-family: Poppins;
            font-size: 13px;
            font-weight: 500;
            color: #000 !important;
            width: 100%;
        }
    
        .counter{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            border-bottom: 1px solid #ccc;
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
            border-top: 1px solid #ccc;
            padding: 4px;
            height: 30px;
            border-radius: 3px;
    
            span{
                color: #000;
            }
            .step-title{
                font-weight: 700;
            }
        }
        .inputs{
            margin-top: 20px;
            border-radius: 3px;
        }
        .inputs-field{
            font-size: 14px;
            font-family: Poppins;
            height: 50px;
        }
        .btn-success{
            text-transform: uppercase;
            font-size: 13px;
            font-family: Poppins;
            background: #24562B;
            border: 0px;
            height: 45px;
        }
        .footer{
            display: flex;
            flex-direction: column;
        }
        .copyright{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-family: Poppins;
            font-size: 13px;
            color: #fff;
            //width: 100%;
    
            span{
                a {
                    color: #fff;
                    text-decoration: none;
                    font-weight: bold;
                    font-size: 14px;
                }
            }
        }
        @media screen and (max-width: 768px) {
            .background{
                font-family: Poppins !important;
                height: 100%;
                position: relative;
                backdrop-filter: blur(16px);
                background: url(../assets/bg.jpg) no-repeat fixed center;
                background-size: cover;
            }
            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: rgba(36, 86, 43, 0.921)/* Change the color and opacity here */
            }
            .img-vendor{
                width: 100%;
            }
            .container-x{
                padding: 0rem;
            }
            .header{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-content: center;
                padding-top: 4rem;
        
                .logo{
                    img{
                        height: 50px;
                        width: auto;
                    }
                }
            }
            .login-container{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
            }
            .app-title{
                font-size: 20px;
                font-weight: bold;
                color: #fff;
                padding: 0rem;
                text-align: center;
                h2{
                    font-family: Poppins;
                    font-size: 20px;
                    color: white;
                    text-shadow: 2px 2px 4px blue;
                }
                p{
                    font-family: Poppins;
                    font-size: 10px;
                }
            }
            .login-form{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                padding: 0rem;
            }
            .login-form-inner{
                padding: 1.2rem;
                width: 100%;
            }
    
            .get-started{
                font-size: 20px;
            }
            
            .copyright{
                margin-top: 20px;
            }
    
        }
    </style>