<template>
    <div class="background">
        <div class="overlay"></div>
        <div class="container-fluid">
            <div class="col-lg-12 pt-4 header">
                <div class="logo"></div>
                <div class="login"><span class="account">Already have an account?</span> <router-link to="/login">Login</router-link></div>
            </div>

            <div class="col-lg-12 col-sm-12 login-container">
                <div class="col-lg-8 col-sm-8 app-title">
                    <div class="logo"><img src="logo.png" alt="PlePower"></div>
                    <h2>PlePower</h2> 
                    <p>Fast, Reliable & Secured Transactions</p>
                </div>
            
                <div class="col-lg-4 col-sm-4 login-form">
                    <div class="login-form-inner">
                        <p class="text-black text-uppercase text-center">Enter the code sent to your email address</p>
                        <div class="inputs1"> 
                            <input type="text" maxlength="1" id="text" class="form-control inputs-field1"  v-model="verify_code1" required @input="moveToNext(1)" ref="input1">
                            <input type="text" maxlength="1" id="text" class="form-control inputs-field1"  v-model="verify_code2" required @input="moveToNext(2)" ref="input2">
                            <input type="text" maxlength="1" id="text" class="form-control inputs-field1"  v-model="verify_code3" required @input="moveToNext(3)" ref="input3">
                            <input type="text" maxlength="1" id="text" class="form-control inputs-field1"  v-model="verify_code4" required @input="moveToNext(4)" ref="input4">
                        </div>
                    
                        <div class="btn-inputs">
                            <button id="submit4" class="btn btn-danger btn-block" :disabled="isSubmitButtonDisabled1" @click="verify_email()" v-if="!loading4">Validate</button>
                            <button class="btn btn-danger btn-block" type="button" disabled v-if="loading4">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                        </div>               
                    </div>
                    <p>&nbsp;</p> 
                </div>
            </div>
        </div>
       
    </div>
    </template>
    <script>
    import Vue from 'vue'
    import VueCountryCode from "vue-country-code";
    import swal from 'sweetalert2';
    
    Vue.use(VueCountryCode);
    
    export default {
        
        name: 'SignupPage',
        components: {
    
        },
        
        data() {
        return {
            appTitle: "PlePower",
            getStarted: true,
            verifyEmail: false,
            submit_3: false,
            firstname: '',
            lastname: '',
            username: '',
            email_address: '',
            phone_number: '',
            password: '',
            confirm_password: '',
            phone_number_code: '',
            verify_code1: '',
            verify_code2: '',
            verify_code3: '',
            verify_code4: '',
            loading3: false,
            loading4: false,
    
            // TIMER SETTING
            totalSeconds: 0, // Initialize the total seconds
            timer: null,
            tokenValid: true,
            terms_conditions: '',
            selectedCategory: '',
          
        };
      },
    
      beforeMount(){
        document.title = this.appTitle
      },
      mounted() {
       
      },
      created(){
        
      },
      computed: {
        formattedTime() {
            //const displayHours = Math.floor(this.totalSeconds / 3600);
            const displayMinutes = Math.floor((this.totalSeconds % 3600) / 60);
            const displaySeconds = this.totalSeconds % 60;
            return `${displayMinutes.toString().padStart(2, '0')} : ${displaySeconds.toString().padStart(2, '0')}`;
            //return `${displayHours.toString().padStart(2, '0')} : ${displayMinutes.toString().padStart(2, '0')} : ${displaySeconds.toString().padStart(2, '0')}`;
        },
    
        isPhoneNumberValid() {
        return (
          this.phone_number === ''
        );
      },
      isTokenValid(){
        return (
          this.validate_token === ''
        );
      },
      isSubmitButtonDisabled() {
        return (
          this.firstname === '' ||
          this.lastname === '' ||
          this.email_address === '' ||
          this.phone_number === '' ||
          this.password === '' ||
          this.confirm_password === '' ||
          this.terms_conditions === ''
        );
      },
      isSubmitButtonDisabled1() {
            return (
            this.verify_code1 === '' ||
            this.verify_code2 === '' ||
            this.verify_code3 === '' ||
            this.verify_code4 === ''
            );
        }
      },
    
      methods:{

        moveToNext(index) {
            const verifyCode = this[`verify_code${index}`];
            if (verifyCode.length === 1) {
                const nextIndex = index + 1;
                if (nextIndex <= 4) {
                    this.$refs[`input${nextIndex}`].focus();
                }
            }
        },
        handlePaste(event) {
        event.preventDefault(); // Prevent the default paste behavior
        const pasteData = event.clipboardData.getData('text');
        if (pasteData.length === 4) {
            this.verify_code1 = pasteData[0];
            this.verify_code2 = pasteData[1];
            this.verify_code3 = pasteData[2];
            this.verify_code4 = pasteData[3];

            // Move focus to the last input field
            this.$nextTick(() => {
            this.$refs.input4.focus();
            });
        }
        },

        showText(){
            const input =  document.getElementById('show_text');
            if (input.getAttribute("type") === "password") {
                input.setAttribute("type", "text");
                    document.getElementById('eye_close').style.display="none";
                    document.getElementById('eye_open').style.display="block";
                } else {
                input.setAttribute("type", "password");
                    document.getElementById('eye_close').style.display="block";
                    document.getElementById('eye_open').style.display="none";
            }
        },
        showText2(){
            const input =  document.getElementById('show_text2');
            if (input.getAttribute("type") === "password") {
                input.setAttribute("type", "text");
                    document.getElementById('eye_close2').style.display="none";
                    document.getElementById('eye_open2').style.display="block";
                } else {
                input.setAttribute("type", "password");
                    document.getElementById('eye_close2').style.display="block";
                    document.getElementById('eye_open2').style.display="none";
                }
        },
        onSelect({name, iso2, dialCode}) {
           console.log(name, iso2, dialCode);
           this.phone_number_code = dialCode
        },
        select1(){
            if (this.education_level == "") {
                document.getElementById("submit1").disabled = false
                return;
            }
    
            document.getElementById("submit1").disabled = true
        },
    
        select2(){
            if (this.work_experience == "") {
                document.getElementById("select_field").disabled = false
                return;
            }
            document.getElementById("select_field").disabled = true
        },
    
        select3(){
            if (this.interests == "") {
                document.getElementById("submit3").disabled = false
                return;
            }
            document.getElementById("submit3").disabled = true
        },
    
        select4(){
            if (this.work_field == "") {
                document.getElementById("submit2").disabled = false
                return;
            }
            document.getElementById("submit2").disabled = true
        },
    
        async submit_form() {
            try {

                if (this.password != this.confirm_password) {
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: 'Password does not match',
                    })
                    return;
                }

                if (this.terms_conditions == true) {
                    this.terms_conditions = 1
                }

                this.loading3 = true

                const response = await this.$http.post('/create-user', {
                    firstname: this.firstname,
                    lastname: this.lastname,
                    username: '',
                    email_address: this.email_address,
                    phone_number: this.phone_number,
                    phone_number_code: this.phone_number_code,
                    password: this.password,
                    confirm_password: this.confirm_password,
                    selectedCategory: this.selectedCategory,
                    terms_conditions: this.terms_conditions,
                })

                this.loading3 = false

                if( !response.data.success ){
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })
                    return;
                }

                swal.fire({
                    icon: 'success',
                    title: 'Successful',
                    width: 450,
                    height: 5,
                    text: response.data.message,
                })

                this.getStarted = false
                this.verifyEmail = true
                return;
                
            } catch (err) {
                console.log(err);
            }
        },

        async verify_email(){
            try {

                if (!this.verify_code1) {
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: 'Code is required',
                    })
                    return;
                }
                if (!this.verify_code2) {
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: 'Code is required',
                    })
                    return;
                }
                if (!this.verify_code3) {
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: 'Code is required',
                    })
                    return;
                }
                if (!this.verify_code4) {
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: 'Code is required',
                    })
                    return;
                }

                this.loading4 = true
                this.email_address = localStorage.getItem("email");
                this.password = localStorage.getItem("password");
                
                const verify_code = this.verify_code1+''+this.verify_code2+''+this.verify_code3+''+this.verify_code4

                const response = await this.$http.post('/verify-otp', {
                    passcode: verify_code,
                })

                this.loading4 = false

                if( !response.data.success ){
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })
                    return;
                }

                await localStorage.setItem("otp", verify_code)
                this.$router.push('/create-password')

                return;

                } catch (err) {
                console.log(err);
                }
        },
    
        validatePassword() {
        // Get the password and confirm_password values
        var password = document.getElementById("password").value;
        var confirm_password = document.getElementById("confirm_password").value;
    
        // Define regular expressions for each requirement
        var digitRegex = /\d/;
        var letterRegex = /[a-zA-Z]/;
        var specialCharRegex = /[@#$%^&*!]/;
    
        // Check if the passwords match
        if (password !== confirm_password) {
            document.getElementById("password-feedback").style.color = "red";
            document.getElementById("password-feedback").textContent = "Passwords do not match!";
            return;
        }
    
        // Check each requirement individually
        var missingRequirements = [];
        if (!password.match(digitRegex)) {
            missingRequirements.push("a digit");
        }
        if (!password.match(letterRegex)) {
            missingRequirements.push("a letter");
        }
        if (!password.match(specialCharRegex)) {
            missingRequirements.push("a special character (@#$%^&*!)");
        }
    
        // Provide feedback based on the missing requirements
        if (missingRequirements.length > 0) {
            var errorMessage = "Password is not strong. Missing: " + missingRequirements.join(', ');
            document.getElementById("password-feedback").style.color = "red";
            document.getElementById("password-feedback").textContent = errorMessage;
            // You can further customize the error message or take other actions as needed
        } else {
            document.getElementById("password-feedback").style.color = "green";
            document.getElementById("password-feedback").textContent = "Password is strong and matches!";
            // You can proceed with the signup process here
        }
        },
    
        startCountdown(hours, minutes) {
                this.totalSeconds = (hours * 3600) + (minutes * 60);
                this.updateTimer();
        },
        updateTimer() {
            if (this.totalSeconds > 0) {
                this.totalSeconds--;
                this.timer = setTimeout(this.updateTimer, 1000);
            }
        },
        stopTimer() {
            if (this.totalSeconds > 0) {
                this.totalSeconds = 0;
            }
        },
        stopCountdown(hours, minutes) {
                this.totalSeconds = (hours * 3600) + (minutes * 60);
                this.stopTimer();
        },
        checkInput() {
          this.tokenValid = this.validateFiveDigitInput(this.validate_token);
        },
        validateFiveDigitInput(input) {
          // Regular expression to match exactly five digits
          var regex = /^\d{5}$/;
    
          // Test the input against the regular expression
          return regex.test(input);
        },
    
      }
    }
    </script>
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
    
    .terms-conditions{
        margin-top: 10px;
        padding: 0.3rem;
        text-align: center;
        font-size: 10px;
        span{
            color: #24562B;
        }
       
        a {
            margin-top: 10px;
            text-decoration: none;
            font-size: 10px;
        }
    }
    .form-check-label{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
    }
    .p-viewer, .p-viewer2{
        float: right;
        margin-top: -27px;
        margin-right: 10px;
        position: relative;
        z-index: 1;
        cursor:pointer;
        font-size: 12px;
    }
    .background{
        height: 100%;
        position: relative;
        backdrop-filter: blur(16px);
        background: url(../assets/bg.jpg) no-repeat fixed center;
        background-size: cover;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(36, 86, 43, 0.921)/* Change the color and opacity here */

      }
      
        .container{
            display: flex;
            flex-direction: column;
            margin-left: 0px;
            margin-right: 0px;
            margin-bottom: 0px;
            width: 100%;
        }
        .header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            text-transform:normal;
            font-size: 12px;
            font-weight: 700;
            color: #fff;
            font-family: Poppins;
            margin-bottom: 10px;
            padding-right: 50px;
            .logo{
                img{
                    height: 50px;
                    width: auto;
                }
            }
    
            a{
                color: #fff;
                text-decoration: none;
                font-weight: 700;
            }
            .login{
                font-weight: 500;
                color: #fff !important;
            }
        }
        .login-container{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    
        .get-started{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 10px;
            padding-bottom: 20px;
            font-family: Poppins;
            font-size: 30px;
            font-weight: 600;
            word-wrap: break-word;
            text-align: center;
            color: #fff;
            text-transform: capitalize;
        }
        .app-title{
            display: flex;
            flex-direction: column;
            align-items:center;
            justify-content: center;
            font-family: Poppins;
            font-size: 30px;
            font-weight: bold;
            text-align: right;
            color: #fff;
            padding: 4rem;
            text-transform: capitalize;;
            .logo{
                img{
                    height: 70px;
                    width: auto;
                }
            }
        
            h2{
                font-family: Poppins;
                font-size: 60px;
                color: white;

            }
            p{
                font-family: Poppins;
                font-size: 14px;
                font-weight: bold;
                color: #fff;
                text-transform: capitalize;
                text-align: center;
            }
        }
     
        .login-form{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .login-form-inner{
            display: flex;
            flex-direction: column;
            background: #fff;
            border-radius: 5px;
            padding: 2rem;
            width: 100%;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            font-family: Poppins;
            font-size: 13px;
            font-weight: 500;
            color: #000 !important;
        }
    
        .counter{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            border-bottom: 1px solid #ccc;
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
            border-top: 1px solid #ccc;
            padding: 4px;
            height: 30px;
            border-radius: 3px;
    
            span{
                color: #000;
            }
            .step-title{
                font-weight: 500;
                font-size: 12px !important;
            }
        }
        .inputs{
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            border-radius: 3px;
            font-size:11px;
            font-weight: 400;

            .inputs-field{
                font-size: 12px;
                font-family: Poppins;
                height: 40px;
            }
    
           div{
            margin: 2px;
            .inputs-field{
                font-size: 12px;
                font-family: Poppins;
                height: 40px;
            }
           }
        }
        .inputs1{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
            border-radius: 3px;
            font-size:11px;
            font-weight: 400;

            .inputs-field1{
                font-size: 14px;
                font-weight: bold;
                font-family: Poppins;
                text-align: center;
                height: 40px;
                width: 50px;
                margin: 5px;
                border: solid 1px;
            }
        }
        .inputs2{
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 15px;
            margin-bottom: 10px;
        }
        .btn-inputs1{
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            border-radius: 3px;
        }
        .btn-inputs{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            margin-top: 10px;
            border-radius: 3px;
        }
        
        .btn-danger{
            text-transform: uppercase;
            font-size: 13px;
            font-family: Poppins;
            background: #24562B;
            border: 0px;
            height: 40px;
        }
        .inputs-field{
            font-size: 14px;
            font-family: Poppins;
            height: 50px;
            //border: #24562B solid 1px;
        }
        .footer{
            display: flex;
            flex-direction: column;
        }
        .copyright{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-family: Poppins;
            font-size: 13px;
            color: #fff;
            background: #cccccc3b;
            padding: 0.3rem;
            width: 100%;
            //margin-top: 40px;
            bottom: 0;
            position: absolute;
            flex-shrink: 0;
        }
        .login{
            font-weight: 800;
            color: #fff !important;
            font-size: 15px
    
        }
    
        .timer{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            padding: 0.4rem;
            margin-top: -10px;
            margin-bottom: -10px;
            font-size: 20px;
           
        }

        body, html {
            height: 100%;
            margin: 0;
        }
       
        .login-container1 {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding: 20px; /* Optional: Add padding */
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better appearance */
            border-radius: 8px; /* Optional: Add border radius for better appearance */
        }
        
        .inputs1 input {
            margin-bottom: 10px; /* Optional: Add margin between inputs */
        }
        
        .btn-inputs button {
            margin-top: 10px; /* Optional: Add margin above the button */
        }
        
    
        @media screen and (max-width: 768px) {
            .background{
                font-family: Poppins !important;
                height: 100%;
                position: relative;
                backdrop-filter: blur(16px);
                background: url(../assets/bg.jpg) no-repeat fixed center;
                background-size: cover;
            }
            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: #24562B; /* Change the color and opacity here */
              }
           
            .header{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-content: center;
                width: 100%;
                padding-right: 20px;
        
                .logo{
                    img{
                        height: 35px;
                        width: auto;
                    }
                }
            }
            .login{
                font-weight: 800;
                text-align: right;
                color: #000 !important;
            }
            .account{
                display: none;
            }
            .get-started{
                font-size: 14px;
            }
            .login-container{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            .login-container1 {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
                padding: 0px; /* Optional: Add padding */
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better appearance */
                border-radius: 8px; /* Optional: Add border radius for better appearance */
            }
            .app-title1{
                display: none
            }
            .app-title{
                font-size: 20px;
                font-weight: bold;
                color: #fff;
                padding: 0rem;
                text-align: center;
                h2{
                    font-size: 20px;
                }
                p{
                    font-family: Poppins;
                    font-size: 10px;
                }
            }
            .login-form{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
            }
            .login-form-inner{
                width: 100%;
                padding: 0.4rem;
            }
        }
    </style>