<template>
    <div style="background: #cccccc3d">
        <!-- top navbar -->
        <div class="top-header">
            <div>
                PlePower
            </div>
            <div>
                <h6>Make your payment</h6>
            </div>
            <div>
                <i class="fa fa-phone"></i> Contact Us: 0813XXXXXX
            </div>
        </div>
      
        <div class="container">
            <router-view />
        </div>

        <div class="footer">
            Privary Policy | Disclaimer
            <hr>
            Follow us on: &nbsp; <i class="fa fa-linkedin-square"></i> &nbsp; <i class="fa fa-facebook-square" aria-hidden="true"></i> &nbsp; <i class="fa fa-youtube-square"></i>
            <br> 
            &copy;{{ new Date().getFullYear() }} {{ appTitle}} All rights reserved.
        </div>
    </div>
</template>
<script>
export default {

    name: "CheckoutDashboard",
    components: {

    },

    data(){
        return {
            appTitle: 'PlePower',
            firstname: '',
            lastname: '',
            email: '',
            name_initial: '',
            user_role: '',
            brands: [],
            categorys: [],
        };
    },
    
    beforeMount(){
        document.title = this.appTitle
    },
    mounted() {
        this.user_role = localStorage.getItem("user_role");
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.email = localStorage.getItem("email");
        if (this.firstname) {
        this.name_initial = this.firstname.charAt(0);
        } else {
        this.name_initial = ''; // Handle the case where firstname is empty
        }
        this.fetchcategorys()
        this.fetchbrands()
    },
    created(){
        
    },
    computed: {
        
    },
    methods:{
        async fetchcategorys() {
            try {
               const response =  await this.$http.get('/fetch-all-categories', {
            })

            this.categorys = response.data.categories;
                                   
            } catch (err) {
              console.log(err);
          }
        },

        async fetchbrands() {
            try {
               const response =  await this.$http.get('/get-brands', {
            })
            this.brands = response.data.brands              
            } catch (err) {
              console.log(err);
          }
        },
    }
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
/* Style The Dropdown Button */

.top-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
    background: #fff;
    color: #3c3a3a;
    padding: 10px;
    box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);
    font-family: Poppins;
    font-size: 12px;
}

.top-bar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 1.9rem;
    font-family: Poppins !important;
    font-size: 18px;
    height: 40px;
    padding: 1.2rem;
    background: #2979FF;
    opacity: 2px;
    color: #fff;
}
.search{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 45%;
}
.inputs-field{
    font-size: 16px;
    font-family: Poppins;
    height: 45px;
    border-radius: 0px;
    position: relative;
}
.search-button{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.6rem;
    background: #2979FF;
    color: #fff;
    border-radius: 0px;
    font-family: Poppins;
    font-size: 18px;
    text-transform: capitalize;
    height: 45px;
}

.menu-cart{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 1.9rem;
    font-family: Poppins !important;
    font-size: 16px;
    color:#312F33;
}
.dropbtn {
   // background-color: #4CAF50;
    color: #4f4d4d;
    //padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #000;
    color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 3px 16px;
    text-decoration: none;
    display: block;
    border-bottom: solid 1px #ccc;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #474646}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    //background-color: #3e8e41;
  }
#green {
    height: 100%;
    background: #fff;
    text-align: center;
    color: black;
    font-family: Poppins;
}
.dropdown-menu {
    margin-left: 250px;
    background: #fff;

    .dropdown-item:hover{
        color: #ff4d63;
        font-weight: 650;
    }
}

.cart{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px !important;
}
.bars{
    padding-left: 1.9rem;
    font-size: 20px;
}
.brand-category {
    padding: 0.4rem;
    font-size:14px; 
    text-align: left; 
    text-transform: uppercase
}
.navbar-bgcolor{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #fff;
    color: #3c3a3a;
    font-family: Poppins;
    border-bottom: solid 1px #ccc;
   // box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);

    a{
        color: #ccc !important;
        text-decoration: none;
        font-size: 13px;

        img{
            width: 45px;
        }
    }
    a:hover{
        color: #fff;
    }
}
.navbar-brand{
    font-weight: 700;
    font-size: 14px !important;
    padding: 14px 40px;
}
.nav-link:hover{
    background: #f1b3ac;
    border-radius: 4px;
}
.avater{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 14px;

    span{
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        width: 30px;
        height: 30px;
        background: #312F33;
        border-radius: 47px;
        margin: 10px;
    }
}
.footer{
   
    flex-shrink: 0;
    background: #f6f1f2;
    font-family: Poppins;
    box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);
    color: #0d0d0d;
    padding: 10px;
    text-align: center;
    //position: absolute;
    bottom: 0;
    height: 120px;
    width: 100%;
    font-size: 12px;
}

@media screen and (max-width: 768px) { 

    .top-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 70px;
        background: #fff;
        color: #3c3a3a;
        padding: 4px;
        box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);
        font-family: Poppins;
        font-size: 10px;
    }
    .bars{
        padding-left: 0.5rem;
    }
    .navbar-brand{
        display: none;
    }
    .search{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 90%;
    }
    .inputs-field{
        font-size: 14px;
        height: 30px;
    }
    .search-button{
        font-size: 14px;
        height: 30px;
    }
    .menu-cart{
        margin-left: 50%;
        font-size: 12px;
    }
    .cart{
        font-size: 13px !important;
    }
    .dropdown-menu {
        margin-left: 0;
    }

    .dropdown {
        font-size: 12px;
      }
    
    .avater{
        font-size: 12px;
        display: flex;
    }
}

</style>