<template>
    <div class="background">
        <div class="overlay"></div>
        <div class="container-fluid">
            <div class="header pt-10">
                <div class="logo"></div>
                <div class="login"></div>
            </div>
            <div class="col-lg-12 col-sm-12 login-container">
                <div class="col-lg-8 col-sm-8 app-title">
                    <div class="logo"><img src="logo.png" alt="PlePower"></div>
                       <h2>PlePower</h2> 
                       <p>Fast, Reliable & Secured Transactions</p>
                    </div>
                <div class="col-lg-4 col-sm-4 login-form">
                    <p class="text-white text-uppercase">Forgot Password</p>
                    <div class="login-form-inner">
                        <div class="inputs"> 
                            <label for="">Email *</label>
                            <input type="email" class="form-control inputs-field" placeholder="Eter your email address" v-model="email_address">
                        </div>
                        <div class="inputs">
                            <p class="forgot-password"> <router-link to="/login"><i class="fa fa-arrow-left"></i> Back</router-link></p>
                        </div>
                        <div class="inputs btn-padding">
                            <button id="submit4" class="btn btn-success btn-block" :disabled="isSubmitButtonDisabled" @click="submit()" v-if="!loading">Submit</button>
                            <button class="btn btn-success btn-block" type="button" disabled v-if="loading">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                        </div>
                    </div>
                    <div class="footer pt-5">
                        <div class="copyright">
                            <!-- <p>Don't have an account? <span><router-link to="/register">Get Started</router-link></span></p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    <script>
    import Vue from 'vue'
    import VueCountryCode from "vue-country-code";
    import swal from 'sweetalert2';
    
    Vue.use(VueCountryCode);
    
    export default {
        
        name: 'ForgotPassword',
        components: {
    
        },
        
        data() {
        return {
          appTitle: "PlePower",
         
          submit_4: false,
          email_address: "",
          password: "",
          loading: false,
        };
      },
    
      beforeMount(){
        document.title = this.appTitle
      },
      mounted() {
       
      },
      created(){
        
      },
      computed: {
        isSubmitButtonDisabled() {
        return (
          this.email_address === ''
        );
      }
      },
    
      methods:{
    
        async submit() {

            if (!this.email_address) {

                swal.fire({
                    icon: 'warning',
                    title: 'Error...',
                    width: 450,
                    height: 4,
                    text: 'Please enter your email address',
                })
                return;
            }

            try {

                this.loading = true;

                this.response = await this.$http.post('/forgot-password1', {
                    email_address: this.email_address,
                });

                this.loading = false;

                if (!this.response.data.success) {

                    swal.fire({
                        icon: "error",
                        title: "Error",
                        width: 450,
                        height: 5,
                        text: this.response.data.message,
                    });

                    return;

                } else {

                    swal.fire({
                        icon: "success",
                        title: "Success",
                        width: 450,
                        height: 5,
                        text: this.response.data.message,
                    });

                    return;
                }

            } catch (err) {
                console.log(err);
            }


            },
    
      }
    }
    </script>
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
        .background{
            height: 100%;
            position: relative;
            backdrop-filter: blur(16px);
            background: url(../assets/bg.jpg) no-repeat fixed center;
            background-size: cover;
        }
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba(36, 86, 43, 0.921)/* Change the color and opacity here */
        }
      
        .login-container{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
        .header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            padding: 10px;
            text-transform:uppercase;
            font-size: 12px;
            font-weight: 700;
            color: #fff;
            font-family: Poppins;
    
            .logo{
                img{
                    height: 50px;
                    width: auto;
                }
            }
    
            a{
                color: #504e4e;
            }
        }
        .get-started{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 10px;
            padding-bottom: 20px;
            font-family: Poppins;
            font-size: 25px;
            font-weight: 600;
            word-wrap: break-word;
            text-align: center;
            color: #fff;
        }
        .app-title{
            display: flex;
            flex-direction: column;
            align-items:center;
            justify-content: center;
            font-size: 40px;
            font-weight: bold;
            text-align: right;
            color: #fff;
            padding: 4rem;
            width: 60%;
            text-transform: capitalize;

            .logo{
                img{
                    height: 70px;
                    width: auto;
                }
            }
            h2{
                font-family: Poppins;
                font-size: 60px;
                color: white;
                //text-shadow: 2px 2px 4px blue;
            }
            p{
                font-family: Poppins;
                font-size: 14px;
                font-weight: bold;
                color: #fff;
                text-transform: capitalize;
                text-align: center;
            }
        }
        .login-form{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 40%;
            height: 100%;
            padding-right: 3rem;
        }
    
        .forgot-password{
            a {
                color: #24562B;
                text-decoration: none;
            }
        }
        .login-form-inner{
            display: flex;
            flex-direction: column;
            background: #fff;
            border-radius: 5px;
            padding: 2rem;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            font-family: Poppins;
            font-size: 13px;
            font-weight: 500;
            color: #000 !important;
            width: 100%;
        }
    
        .counter{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            border-bottom: 1px solid #ccc;
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
            border-top: 1px solid #ccc;
            padding: 4px;
            height: 30px;
            border-radius: 3px;
    
            span{
                color: #000;
            }
            .step-title{
                font-weight: 700;
            }
        }
        .inputs{
            margin-top: 20px;
            border-radius: 3px;
        }
        .btn-padding{
            margin-top: -5px;
        }
        .inputs-field{
            font-size: 14px;
            font-family: Poppins;
            height: 50px;
           // border: #24562B solid 1px;
        }
        .btn-success{
            text-transform: uppercase;
            font-size: 13px;
            font-family: Poppins;
            background: #24562B;
            border: 0px;
            height: 45px;
        }
        .footer{
            display: flex;
            flex-direction: column;
        }
        .copyright{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-family: Poppins;
            font-size: 13px;
            color: #fff;
            //width: 100%;
    
            span{
                a {
                    color: #24562B;
                    text-decoration: none;
                    font-weight: bold;
                    font-size: 14px;
                }
            }
        }
        @media screen and (max-width: 768px) {
            .background{
                font-family: Poppins !important;
                height: 100%;
                position: relative;
                backdrop-filter: blur(16px);
                background-size: cover;
            }
            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: #24562B; 
              }
           
            .header{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-content: center;
                padding-top: 2rem;
        
                .logo{
                    img{
                        height: 50px;
                        width: auto;
                    }
                }
            }
            .login-container{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            .app-title{
                font-size: 20px;
                font-weight: bold;
                color: #fff;
                padding: 0rem;
                text-align: center;

                h2{
                    font-family: Poppins;
                    font-size: 15px;
                    font-weight: bold;
                    color: #fff;
                    text-transform: normal;
                    text-align: center;
                }
            }
            .login-form{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                padding-right: 0rem;
                
            }
            .login-form-inner{
                width: 100%;
            }
    
            .get-started{
                font-size: 20px;
            }
    
    
        }
    </style>